/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap'); */
/* .pdf_h1{
    color: blue;
    font-size: 50px;
} */



@font-face {
  font-family: 'Poppins';
  src:  url('../assets/fonts/PoppinsRegular400.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src:  url('../assets/fonts/PoppinsLight300.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src:  url('../assets/fonts/PoppinsMedium500.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src:  url('../assets/fonts/PoppinsBold700.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}


@font-face {
  font-family: 'calibril-regular';
  src:  url('../assets/fonts/CalibriRegular.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'calibril-bold';
  src:  url('../assets/fonts/CalibriBold.TTF') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'calibril-light';
  src:  url('../assets/fonts/CalibriLight.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'calibril-light';
  src:  url('../assets/fonts/CalibriLight.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Monstserrat';
  src:  url('../assets/fonts/Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
}


@font-face {
  font-family: 'Monstserrat';
  src:  url('../assets/fonts/Montserrat-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Monstserrat';
  src:  url('../assets/fonts/Montserrat-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Monstserrat';
  src:  url('../assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Monstserrat';
  src:  url('../assets/fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: 900;
  font-style: bold;
}














/* @font-face {
  font-family: 'Poppins';
  src:  url('../assets/fonts/PoppinsSemiBold600.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
} */

@font-face {
  font-family: 'Consolas';
  src:  url('../assets/fonts/consola.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Poppins';
  src:  url('../assets/fonts/PoppinsSemiBold600.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}





/* .kendo-pdf-document div {
    border: 2px solid black;
    background: url("https://stimg.cardekho.com/images/carexteriorimages/630x420/Lamborgjhini/Urus/4418/Lamborghini-Urus-V8/1621927166506/front-left-side-47.jpg?tr=w-300");
  } */

  .page-body{
    padding-top: 100px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
  }

  .heading-text-dark-blue{
/* Style for "Timeline R" */
color: #fff;
font-family: "Adobe-Garamond" !important;
font-size: 24px;
font-weight: 700;
font-style: normal;
letter-spacing: normal;
line-height: 187.5px;
text-align: left;
/* Text style for "T, imeline" */
font-style: normal;
letter-spacing: -1.2px;
line-height: normal;
  }

.heading-text-light{
color: #fff;
font-family: "Adobe-Garamond" !important;
font-size: 24px;
font-weight: 500;
font-style: normal;
letter-spacing: normal;
line-height: 187.5px;
text-align: left;
/* Text style for "T, imeline" */
font-style: normal;
letter-spacing: -1.2px;
line-height: normal;
}


.service-container{
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
}

.background-image{
    background-repeat: no-repeat;
    background-position: center center;
    text-align: center;
    padding: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
}

.para-text{
color: #fff;
font-family: "Adobe-Garamond" !important;
font-size: 14px;
font-weight: 400;
font-style: normal !important;
letter-spacing: normal !important;

text-align: left;
/* Text style for "Marketing" */
font-style: normal;
letter-spacing: normal;
line-height: 1.6 !important;
margin-bottom: 0.5rem !important;
}

.main-heading-blue{
  /* Style for "Machine Le" */
color: #fff;
font-family: "Adobe-Garamond" !important;
font-size: 24px;
font-weight: 700;
font-style: normal;
letter-spacing: normal;
line-height: 187.5px;
text-align: left;
font-style: normal;
letter-spacing: -1.2px;
line-height: normal;
margin-top: 10px;
/* margin-bottom: 10px; */
}

.price-heading-text{
  /* Style for "$10,000/mo" */

color: #fff;
font-family: "Adobe-Garamond" !important;
font-size: 14px;
font-weight: 700;
font-style: normal;
letter-spacing: normal;
line-height: 187.5px;
text-align: left;
/* Text style for "$, 10,000/" */
font-style: normal;
letter-spacing: -1.04px;
line-height: normal;
margin-bottom: 10px;
}

.long-heading-text{
  /* Style for "Marketing" */
color: #fff;
font-family: "Adobe-Garamond" !important;
font-size: 12px;
font-weight: 500;
font-style: normal;
letter-spacing: normal;
line-height: 34px;
text-align: left;
/* Text style for "Marketing" */
font-style: normal;
letter-spacing: normal;
line-height: normal;
}

.bullet-container{

    display: flex ;
    width: 100%;
    flex-wrap: wrap;

}

.bullet-text{
display: flex;
width: 50%;
padding-right: 20px;
color: #fff;
font-family: "Adobe-Garamond" !important;
font-size: 12px;
font-weight: 400;
font-style: normal;
letter-spacing: normal;

text-align: left;
font-style: normal;
letter-spacing: normal;
line-height: 1.6 !important;
}

.bullet-img{
    width: 12px;
    height: 12px;
    object-fit: contain;
    padding-bottom: 5px;
    margin-right: 10px;
}


.front-page-title-text{
/* Style for "This propo" */
margin-top: 25px;
margin-right: 20px;
color: #fff;
font-family: "Adobe-Garamond";
font-size: 12px;
font-weight: 400;
letter-spacing: normal;
line-height: 187.5px;
text-align: center;
/* Text style for ", This pro" */
font-style: normal;
letter-spacing: -0.36px;
line-height: normal;
padding-left: 20px;;
padding-right: 20px;
}



/* .font-page-main-text{
  margin-top: 550px;
  text-align: center;
  color: #09191e;
font-size: 18px;
font-weight: 400;
} */


.margin-top-5{
  margin-bottom: 5%;
}

.font-Poppins-normal{
  font-family: Poppins !important;
  font-weight: 500;
}

.sprint-lesting-container{
  font-size: 18px;
  font-family: Monstserrat !important;
  font-weight: 600;
  color: #485a60;
  width: 25vw;
  margin: 10px 0 ;
  padding: 10px;
  word-break: break-all !important
 }
 
 .sprint-lesting-container-pdf{
   font-size: 12px;
   font-family: Monstserrat !important;
   font-weight: 700;
   color: #485a60;
  }

  .sprint-heading-imp{
    font-family: "Monstserrat" !important ;
    font-weight: 700 !important;
    color: #7f7f7f;
  }

  .download-animation {
    animation-name: sprint;
    animation-duration: 4s;
  }

  .sprint-heading{
    font-size: 10px;
    font-family: Monstserrat !important;
    font-weight: 500;
    color: #7f7f7f;
    text-align: left;
  }
  
  .sprint-heading-pdf{
    font-size: 10px;
    line-height: 18px;
    font-family: Monstserrat !important;
    font-weight: 600;
    /* color: #1e5f73; */
    color: #58595b;
    text-align: left;
    margin-bottom: 0px;
  }

  .sprint-filter-text{
    font-size: 20px;
    font-family: Poppins !important;
    font-weight: 500;
    color: #1e5f73;
    text-align: left;
  }

  @keyframes sprint {
    0%   {background-color: #107b9b;}
    25%  {background-color: #36bbda;}
    50%  {background-color: #ffbd84;}
    100% {background-color: #fbbdbe;}
  }

  .dot-img-positon {
    width: 5px;
    height: auto;
    object-fit: contain;
    margin-top: 3vh;
    margin-left: 8vh;
  }
  .sprint-main-heading-pdf{
    font-size: 14px;
    font-family: Monstserrat !important;
    font-weight: 900;
    color: #7c8083 ;
    text-align: left;
  }

  .sprint-main-heading{
    font-size: 30px;
    font-family: Monstserrat !important;
    font-weight: 900;
    color: #092ea8;
    text-align: left;
  }

  #sprint-bottom-container h4{
    color: #092ea8;
    font-weight: 900;
  }

  .font-lightgray{
    color: #5d696c !important;
  }
  .pdf-box{
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
    border-radius: 5px;
  }
  
  

  @media (min-width:768px) {
    .sprint-heading{
      font-size: 10px;
    }
    .sprint-filter-text{
      font-size: 12px;
    }
  }

  @media (min-width:1024px) {
    .sprint-heading{
      font-size: 10px;
    }
    .sprint-filter-text{
      font-size: 12px;
    }
    .sprint-lesting-container{
      width: 25vw;
      font-size: 14px;
    }
  }

  @media (max-width : 1025) {
    .react-datepicker__input-container input{
      width: 150px !important;
    } 
    .dot-img-positon{
      margin-left: 3vh;
    }
   
  }


  @media (min-width:1280px) {
    .sprint-heading{
      font-size: 10px;
      font-weight: 600;
    }
    .sprint-filter-text{
      font-size: 16px;
    }
    .sprint-lesting-container{
      width: 45vh;
      font-size: 16px;
    }
    
  }

  @media (min-width:1680px) {
    .sprint-heading{
      font-size: 10px;
    }
    .sprint-filter-text{
      font-size: 20px;
    }
    .sprint-lesting-container{
      width: 25vw;
      font-size: 20px;
    }
    
  }

  
  .agreement-heading-text{
    /* Style for "$10,000/mo" */
  
  color: #494141;
  font-family: "Adobe-Garamond" !important;
  font-size: 14px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 187.5px;
  text-align: left;
  font-weight: 700;
  /* Text style for "$, 10,000/" */
  font-style: normal;
  letter-spacing: -1.04px;
  line-height: normal;
  margin-bottom: 10px;
  }
  
  .para-text-agreement{
    color: black;
    font-family: "Adobe-Garamond" !important;
    font-size: 12px;
    font-weight: 500;
    font-style: normal !important;
    letter-spacing: normal !important;
    
    text-align: left;
    /* Text style for "Marketing" */
    font-style: normal;
    letter-spacing: normal;
    line-height: 1.6 !important;
    margin-bottom: 0.5rem !important;
    }

    .font-16{
      font-size: 16px !important;
    }
    /* ::marker {
      color: blue;
      font-size: 1.2em;
    
    } */

    /* ::marker{
      position: relative;
      top: -20px;
      color: blue;
      font-size: 1.2em;
    } */

    .text-left{
      text-align: left !important;
    }


    /* ol li::marker {
      color: red;
      font-weight: bold;
  
    } */


    .list-style-none{
      list-style-type: none
    }
    

    

    .main-heading-contract{
      /* Style for "Machine Le" */
    font-family: "Poppins" !important;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: normal;
    line-height: 187.5px;
    text-align: left;
    font-style: normal;
    letter-spacing: -1.2px;
    line-height: normal;
    margin-top: 10px;
    /* margin-bottom: 10px; */
    }

    .font-black{
      color: black !important;
    }

    .bullet-text-contract{
      display: flex;
      width: 50%;
      padding-right: 20px;
      color: #09191e;
      font-family: "Poppins" !important;
      font-size: 12px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      font-style: normal;
      letter-spacing: normal;
      line-height: 1.6 !important;
      }
      
    
      .bullet-circle-img-contract{
        width: 5px !important;
        height: 5px !important;
        object-fit: contain !important;
        
      }
      
      .font-20{
        font-size: 20px;
      }

      .font-16{
        font-size: 16px;
      }

      .font-500{
        font-weight: 600 !important;
      }


      .front-page-title-text-contract{
        margin-top: 25px;
        margin-right: 20px;
        color: #09191e;
        font-family:  calibril-bold;
        font-size: 20px;
        font-weight: 400;
        letter-spacing: normal;
        text-align: center;
        line-height: normal;
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 25px;
    }

    .font-bold-contract {
      font-family: Adobe-Garamond !important;
      font-weight: 700;
    }

    .font-bold-contract-light {
      font-family: Adobe-Garamond !important;
      font-weight: 400;
    }

    .mb-10{
      margin-bottom: 10px;
    }

    .pl-10{
      padding-left: 10px;
    }

    .my-50{
      margin-left: 50px !important;
      margin-right: 50px !important;
    }

    .py-50{
      padding-left: 50px !important;
      padding-right: 50px !important;
    }

    .sprint-headerbg {
      background: #092ea8;
      padding: 5px 50px 5px 50px;
    }

   
    #sprint-reporting .headerimg {
      background-repeat: repeat;
      height: 95%;
      width: 100%;
      position: absolute;
      -webkit-animation: headerimg 15s linear infinite;
              animation: headerimg 15s linear infinite;
      margin-top: 0 !important;
    }
    
.sprint-fotter-container {
    display: flex;
    align-items: left
}
    /* banener mving animation */

    .outofScope-listing {
      margin: 1rem;
      /* background-color: #D1D3D4 !important; */
      border: 1px solid gray;
      border-radius: 1rem;
      width:'45vh'
    }

    .pdf-outofScope-listing {
      background-color: #092ea8 !important;
      border: 2px solid #fff;
      border-radius: 10px;
      width:'100%' !important;
      color: #fff;
      word-break:break-all !important;
    }

    .outofScope-listing span {
      color: #092ea8;
    }
    .pdf-outofScope-listing span {
      color: #fff;
      font-family: Monstserrat !important;
      font-size: 16px;
      line-height: 22px;
    }
    .pdf-heading-text{
      font-size: 28px;
      font-family: Monstserrat !important;
      font-weight: 900 !important;
      color: #092ea8 !important;
      text-align: left;
    }

    @media (min-width: 1280px) and (max-width:1439px){
      .sprint-lesting-container {
        width: 100% !important;
        font-size: 14px;
    }
    }

    @media (min-width: 1024px) and (max-width:1279px){
      .sprint-lesting-container {
        width: 100% !important ;
        font-size: 14px;
    }
    }
    
    
#sprint-pdf-container .headerimg{
  z-index: -1;
}

#sprint-pdf-container .border {
  margin: 0;
}


#sprint-reporting .card {
  border-radius: 1rem !important;
}

#sprint-pdf-container .card{
  border-radius: 1rem !important;
}


#sprint-reporting .card-header{
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125)
}