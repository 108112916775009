@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,300,500);

.page404 body {
  background-color: #007aff;
  color: #fff;
  font-size: 100%;
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
}

.page404 .button {
  font-weight: 300;
  color: #fff;
  font-size: 1.2em;
  text-decoration: none;
  border: 1px solid #efefef;
  padding: 0.5em;
  border-radius: 3px;
  float: left;
  margin: 6em 0 0 -155px;
  left: 50%;
  position: relative;
  transition: all 0.3s linear;
}

.page404.button:hover {
  background-color: #007aff;
  color: #fff;
}

.page404 p {
  font-size: 2em;
  text-align: center;
  font-weight: 100;
}

.page404 h1 {
  text-align: center;
  font-size: 15em;
  font-weight: 100;
}
