/* body{
  font-family: 'Montserrat', sans-serif !important
} */

/* @import url("https://use.typekit.net/ybv0twi.css"); */




.div-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* height: 100vh; */
}

.main-container{
  font-family:adone-Garamond !important ;
}

/* .width-60 {
  width: 60%;
} */


@font-face {
  font-family: 'adobe-Garamond';
  src:  url('../assets/fonts/AGaramond-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'adobe-Garamond';
  src:  url('../assets/fonts/AGaramond-Regular.ttf') format('truetype');
  font-style: normal;
}


@font-face {
  font-family: 'adone-Garamond';
  src:  url('../assets/fonts/AGaramond-Semibold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'adone-Garamond';
  src:  url('../assets/fonts/AGaramond-Italic.ttf') format('truetype');
  font-style: italic;
}


@font-face {
  font-family: 'GT America Extended Regular';
  src:  url('../assets/fonts/GT\ America\ Extended\ Regular.ttf') format('truetype');
  font-style: italic;
}

.mt-15 {
  margin-top: 15px;
}

.ServiceQuestionnaireOnly .ServiceQuestionnaire input[type="text"] {
  background-color: transparent;
  border: none;
  color: #0d0d0d;
  padding: 0px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

.d-flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.justify-around{
justify-content: space-around;
}
.w-100 {
  width: 100%;
}

.mx-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.mxt-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.my-20 {
  margin: 20px 0;
}

.mtb-10{
  margin: 10px 0;
}

.mt-2rem{
  margin-top: 2rem;
}
.mt-1rem{
  margin-top: 1rem;
}

.service input {
  padding: 8px 0;
  border-radius: 10px;
  margin: 10px 0;
}

.service-table input[type="text"] {
  /* border: 1px solid;
  text-align: left;
  padding: 5px 10px; */

  border: 2px solid #aaa;
  border-radius: 4px;
  margin: 8px 0;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  transition: 0.3s;
}

.service-table input[type="text"]:focus {
  border-color: dodgerBlue;
  /* box-shadow: 0 0 8px 0 dodgerBlue; */
}

.card-header-background {
  background-color: rgb(236 226 226 / 3%) !important;
  height: 3rem;
  padding-top: 13px;
  font-weight: 600;
}

.justify-center {
  justify-content: center;
}

/* .service-table input[type="text"]:disabled {
  background: #dddddd;
} */

#adminDashboard {
  font-family: Adobe-Garamond, sans-serif !important;
  border-collapse: collapse;
  width: 100%;
}

#sidebar-wrapper{
  font-family: Adobe-Garamond, sans-serif !important;
}

#adminDashboard td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#adminDashboard tr:nth-child(even) {
  background-color: #f2f2f2;
}

#adminDashboard tr:hover {
  background-color: #ddd;
}

#adminDashboard th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #35c4e4;
  color: white;
}

.mt-5 {
  margin-top: 5%;
}

.nav-padding {
  padding: 1px 0;
}

.info-icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.all-unset {
  all: unset;
}

.tooltip {
  width: 400px;
}

.padding-1 {
  padding: 1rem;
}

.ServiceQuestionnaire-width {
  width: 60%;
}
.pdf-img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.curser-pointer {
  cursor: pointer;
}

.td-link {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.ml-5p {
  margin-left: 5%;
  margin-bottom: 0;
}

.input-like {
  border: 1px solid black;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.input-border-none {
  border: none !important;
  flex: 2 !important;
  outline: none !important;
}

.input-width {
  width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.mt-5px {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10%;
}

.login-background {
  background: #ece9e9;
  height: 100vh;
}

.margin-30 {
  margin-top: 30px;
}

.tooltip .tooltip-inner {
  width: 800px;
  white-space: normal;
}

/* // toggel css */

.switch input {
  position: absolute;
  opacity: 0;
}

/**
 * 1. Adjust this to size
 */

.switch {
  display: inline-block;
  font-size: 20px; /* 1 */
  height: 1em;
  width: 2em;
  background: #bdb9a6;
  border-radius: 1em;
}

.switch div {
  height: 1em;
  width: 1em;
  border-radius: 1em;
  background: #fff;
  box-shadow: 0 0.1em 0.3em rgba(0, 0, 0, 0.3);
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  transition: all 300ms;
}

.switch input:checked + div {
  -webkit-transform: translate3d(100%, 0, 0);
  -moz-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

/* toggel css end here  */

.w-80 {
  width: 80%;
}

.w-200 {
  width: 200px;
}

.mx-900 {
  width: 95%;
  overflow-x: auto;
}

.align-slef {
  align-items: center;
  align-self: center;
}

/* // table css */
#title {
  text-align: center;
  font-family: arial, sans-serif;
}

#students {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  border: 3px solid #ddd;
  width: 100%;
  display: flex;
  overflow-x: auto;
  flex-wrap: wrap;
}

#students td,
#students th {
  border: 1px solid #ddd;
  padding: 8px;
  flex: 1;
}

#students tr:nth-child(even) {
  background-color: #f2f2f2;
}

#students tr:hover {
  background-color: #ddd;
}

#students th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #4caf50;
  color: white;
  flex: 1;
}

.overflow-x {
  overflow-x: auto;
}

.w-90 {
  width: 90%;
}

.border {
  border: 1px solid black;
  margin: 2px;
}

.filled-cost table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  /* width: 100%; */
  /* border: none !important; */
  border: none ;
}

.filled-cost td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-weight: 600;
}

/* Scrollbar Styling */

.scroller {
  scrollbar-width: thin !important;
}

.m-20 {
  margin: 20px;
}

.nav-padding-balance {
  padding: 0.58rem 0 !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-1 {
  width: 25%;
}

.myt-5 {
  margin: 5px 0;
}

.mx-50 {
  max-width: 100%;
}

.inside-padding {
  padding: 5px;
}

.outline-none {
  outline: none !important;
}

.margin-top {
  margin-top: 5px;
}

.margin-left {
  margin-left: 5px;
}

.input-pd {
  padding: 5px;
  margin-top: 5px;
}

.margin-r {
  margin-right: 10px;
}

.margin-l-10 {
  margin-left: 10px;
}

.p:first-letter {
  text-transform: capitalize;
}

.text-red {
  color: rgb(253, 31, 31);
}

.text-green {
  color: #058d5be9;
}

.text-blue{
  color: blue;
}
.mb-none {
  margin-bottom: 0px !important;
}

.font-600 {
  font-weight: 600;
}

.displayCost input[type="text"] {
  /* width: 100%; */
  border: 2px solid #aaa;
  border-radius: 4px;
  margin: 8px 0;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  transition: 0.3s;
  max-width: 250px;
}

.displayCost input[type="text"]:focus {
  border-color: dodgerBlue;
  /* box-shadow: 0 0 8px 0 dodgerBlue; */
}

.flex-col {
  flex-direction: column;
}

.searchBox {
  caret-color: transparent !important;
}

.search-wrapper {
  caret-color: transparent !important;
}

.tooltip-inner {
  max-width: 500px !important;
  text-align: left !important;
}
.margin-bottom-10 {
  margin-bottom: 10px;
}

.ml-10p {
  margin-left: 8% !important;
}

.th-width {
  width: 100px !important;
}

.mxy {
  margin: 5px;
}

.mx-10p {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-10 {
  margin-bottom: 7px;
}

.portalReporting th {
  color: black !important;
  background: white !important;
}

.portalReporting tr:nth-child(even) {
  background-color: #fff !important;
}


.width-20{
  width: 20% !important;
}


.project-report{
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
}

.project-report th, td{
  text-align: left;
  padding: 8px;
}

.project-report tr:nth-child(even){background-color: #f2f2f2}

.d-flex-1{
  flex: 1;
}

.mt-20{
  margin-top: 20px;
}

.react-datepicker__input-container{
 width: 220px !important; 
} 
.react-datepicker__input-container input{
  width: 220px !important;
} 

.react-datepicker-wrapper {
  width: 220px !important;
}



.width-5p{
  width: 5%;
}

.width-15p{
  width: 10%;
}

.width-25p{
  width: 25%;
}

.table-size-11{
  font-size: 14px !important;
}



.width-10p{
  width: 10%;
}

.justify-start{
  justify-content: flex-start !important;
}

.margin-left-none{
  margin-left: 0 !important;
}


@media (max-width:1024px) {
  .page-content-wrapper{
    width: 70% !important;
  }
  
}


/* .questionnaire-background {
  background-image: url("/web_app/public/assets/images/question_backgroud.png");
} */

.h-100vh{
  height: 100vh;
}

.circle {
  width: 50px;
  height: 50px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  background: white;
}

.user-icon{
  width: 20px;
  object-fit: contain;
}

.w-60{
  width: 60%;
}


.optionContainer li:hover{
  background-color: #ecf7fa !important;
  color: #35c4e4 !important;
}

.mb-3rem{
  margin-bottom: 3rem;
}

.questionnaire-label{
 font-size: 17px !important;
 font-family: adone-Garamond, serif !important;
 font-weight: 400 !important;
 color: #3e3f4a !important;
 letter-spacing: -0.17px !important;
}

.chip{
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 400;
}


.filled-cost-heading{
  font-size: 18px !important;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 400 !important;
  color: #000000 !important;
  letter-spacing: 0px !important;
}


.filled-cost-label{
  font-size: 16px !important;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 400 !important;
  color: #000000 !important;
  letter-spacing: 0px !important;
}

.h-3_5rem {
  height:3.5rem
}

.mainHeading{
  font-size: 30px !important;
  font-family: adone-Garamond !important;
  font-weight: 700 !important;
  color: #000000 !important;
  background-color:#ecf7fa !important ;
  border-bottom: none !important;
}

.bg-white{
  background-color: white !important;
}

.btn-primary  {
  background-color: #092ea8 !important;
  border-color: transparent !important;
  font-family:adone-Garamond ;
}

/* #bg-image {
  background-image: url("/web_app/public/assets/images/question_backgroud.png");
} */

.padding-bottom-1 {
  padding-bottom: 1px !important;
}

.seleted-item {
  font-weight: 600;
  color: #35c4e4;
}

.flex-end{
  justify-content: flex-end;
}

.pagenation-margin{
  margin-top: 5px;
  margin-right: 12px;
}

.page-item.active .page-link {
    background-color: #35c4e4 !important;
    border-color: #35c4e4 !important;
}

.page-link{
  color: #35c4e4;
}

.self-center{
  align-self: center;
}

.div-col{
  display: flex;
  flex-direction: column;
  height: 90%;
}


.div-col-nohight{
  display: flex;
  flex-direction: column;
 
}

.pagination-outer-div{
  display: flex;
  justify-content: flex-end;
  height: 100%;
}

.pagination-inner-div{
  display: flex;
  flex-direction: column-reverse;
}

.login-btn{
  background-color: #56baed;
    border: none;
    color: white;
    padding: 15px 80px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    text-transform: uppercase;
    font-size: 13px;
    border-radius: 5px 5px 5px 5px;
    margin: 5px 20px 30px 20px;
    transition: all 0.3s ease-in-out;
}

.login div:after{
  top:25px !important;
}

.top-30{
  top: -30px !important;
  width: 20px !important;
  height: 20px !important;
}

.table-height{
  height:70px;
}

.org-name{
    border: 1px solid #dddddd;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    border-right: hidden;
    width: 230px !important;
}

.border-bottom-org{
  border-bottom: 2px solid #dddddd;
}

.org-input-margin{
  margin-top: 16px !important;
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.table-heading-center{
  text-align: center;
  vertical-align: middle;
}

.org-heading-center{ 
  border: none !important;
  width: 230px !important;
  text-align: center !important;
  vertical-align: middle !important;
  height: 75px !important;
}

.overflow-x{
  overflow-x: auto !important;
}

.overflow-y{
  overflow-y: auto !important;
}


.table-style{
    margin: 0 20px;
    border: 1px solid #dddddd;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.m-8{
  margin: 8px  !important;
}

.height-60{
  height: 70px;
  border-bottom: 1px solid #dddddd;;
  margin-bottom: 8px;
  margin-top: 8px;
}


.padding-8{
  padding: 8px;
}
    
.border-gray{
   border: 1px solid #dddddd !important;
}

.filled-cost-org{
  padding-top: 15px;
  padding-left: 8px;
  font-weight: 500;
}

.mlr-8{
  margin: 0 8px;
}


.p-10{
 padding: 10px;
}

.text-center{
  text-align: center;
}

.table-width{
  width: 230px;
  height: 150px;
}

.align-center{
  align-items: center;
}

.tb-width{
  width: 355px !important;
}

.status-box-width{
  width: 100px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.margin-top-10{
  margin-top: 10px;
}

.margin-top-8{
  margin-top: 8px;
}

.box-width{
  width: 100px !important;
}

.mt-08{
 margin-top: 8px;
}

.font-500{
  font-weight: 500;
}

.para-margin{
    margin-top: 0.6rem;
    margin-bottom: 1rem;
    margin-left: 0.4rem;
}

.margin-top-xl{
  margin-top: 1.2rem;
}

.display-row-only{
    border: 1px solid #dddddd;
    display: flex;
    flex-direction: column;
    /* width: 230px !important; */
}

.mtb-0{
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.margin-single-row{
    margin-right: 5px !important;
    margin-left: 5px !important;
    margin-top: 16px !important;
}

.row-input-width{
  width: 197px;
}

.row-td-width{
  width: 230px;
}


.status-box{
  height: 70px;
  width: 100px;
  margin: 0px;
}

.table-row-width{
  height: 78px;
  width: 230px;
}


.admin-status-box{
  width: 110px;
    height: 62px;
    border-bottom: 2px solid #dddddd;
    padding: 0;
    margin: 0;
    font-weight: 600;
    text-align: center;
    padding-top: 12px
}

.height-70{
  height: 70px;
}

.peding-btn{
  padding-top: 20px;
  padding-left: 5px;
}

.pending-text{
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px
}

.padding-text-single-row{
  width: 100px;
}

.padding-green-text{
  padding-left: 20px;
  padding-top: 15px;
}

.mb-0{
  margin-bottom: 0px !important;
}

.padding-b-10{
  padding-bottom: 10px;
}

.single-row-btn-padding{
  padding-top: 17px;
  padding-left: 5px;
}

.border-bottom-hidden{
  border-bottom: hidden !important;
}

.border-left-hidden{
  border-left: hidden !important;
}

.border-right-hidden{
  border-right: hidden !important;
}

.border-top-hidden{
  border-top: hidden !important;
}

.border-hidden{
  border: hidden !important;
}

::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #AAAAAA;
}

.filedCost th td {
  width: 300px !important;
  overflow: scroll;
}


.white-space{
  white-space: nowrap;
}

.filedCost .th-width{
  width: 400px !important;
}

.input-disabled 
 {
  cursor: not-allowed;
  color: grey;
}

.td-disabled {
 background-color:  rgba(243, 236, 236, 0.3) !important;
  border: 1px solid rgba(118, 118, 118, 0.3) !important;
  font-weight: 400;
  color: gray;
  padding: 5px;

}

.selected-cost {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);  
}

.input-checkbox {
  border: none !important;
}

.input-checkbox-border {
  border: 1px solid rgba(118, 118, 118, 0.3) !important;
}

.disabledBackground{
  background-color: #f9f9f9 !important;
}


.border-green{
  border: 1px solid green !important;
}

.bg-none{
  background:none !important;
}

.mr-10{
  margin-right: 10px;
}

.empty-container {
 height: 70vh;
}

.loading-container{
  min-height: 70vh !important;
}

.sprint-container{
  /* border: 2px solid rgba(0, 0, 0, 0.125); */
  padding: 20px 20px;
  border-radius: 20px;
}

#sprint-inner-container {
  padding: 20px;
  border-radius: 2rem 2rem 0 0;
  border: 2px solid #092ea8
}

.container{
  padding: 0 !important;
}

@media (min-width: 1400px) {
  .container {
    max-width: 100% !important;
}
}

@media (min-width: 1200px) {
  .container {
    max-width: 100% !important;
}
}
@media (min-width: 992px) {
  .container {
    max-width: 100% !important;
}
}

@media (min-width: 786px) {
  .container {
    max-width: 100% !important;
}
}

@media (min-width: 576) {
  .container {
    max-width: 100% !important;
}
}

@media (min-width: 440) {
  .container {
    max-width: 100% !important;
    overflow-x: auto !important
}
}


.content-center{
  display: flex;
  justify-content: center;
  align-items: center;
}




.space-evenly{
  justify-content: space-evenly
}

.flex-end{
  justify-content: flex-end;
}

.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-left, .apexcharts-legend.apx-legend-position-top.apexcharts-align-left, .apexcharts-legend.apx-legend-position-right, .apexcharts-legend.apx-legend-position-left {
  display: none;
}

.container-sprint {
  columns: 2 auto;
}



.drop-shadow-xl{
  filter: drop-shadow(0 20px 13px rgb(0 0 0 / 0.03)) drop-shadow(0 8px 5px rgb(0 0 0 / 0.08));
}

.sprint-pdf-card{
  width: 300px;
  height: 200px;
  box-shadow: 0 0.1em 0.3em rgba(0, 0, 0, 0.3);
}

.sprint-bold-text{
  font-weight: 900!important;
}

.box-shadow-pdf{
  box-shadow: 0 0.1em 0.3em rgba(0, 0, 0, 0.3) !important;
}

.space-between{
  justify-content: space-between;
}

.align-center{
  align-items: center;
}


.invite-container{
  display: flex;
  width: 100%;
}

.css-ulvela-MuiTableContainer-root{
  font-family:adone-Garamond !important ;
}

.css-xn82ks-MuiTable-root {
  font-family:adone-Garamond !important ;
}


.flex-3{
  width: 75%;;
}


.w-10{
  width: 10%;
}

.w-40{
  width: 40%;
}

.w-20{
  width: 10%;
}

.pr-1{
  padding-right: 1rem;
}

.pd-2{
  padding-bottom: 2rem;
}

.height-100vh{
  height: 100vh;
}


.resetPassword {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #56baed;
  font-weight: 500;
}


.forgot-password-text{
  margin-bottom: 1rem;
  text-align: left;
  padding-left: 8%;
  font-weight: 600;
  color: #56baed ;
  cursor: pointer;
  font-size: 12px;
}


.ml-10{
  margin-left:10px  !important;
}

.input-width{
  width: 204px !important;
}

.filled-cost-border{
  border: 0px !important;
}

.flex-end{
  justify-content: flex-end;
}

.my-10{
  margin-bottom: 10px;
  margin-top: 10px;
}

.w-45{
  width: 45%;
}

.mt-50{
  margin-top: 50px;
}
.mt-40{
  margin-top: 40px;
}

.font-18{
  font-size: 18px !important;
}

.w-90{
  width: 90%;
}

.text-left{
  text-align: left;
}

.modal{
  overflow-y: hidden !important;
}

.sub-heading{
  font-family:  Adobe-Garamond !important;
  font-size: 12px !important;
  font-weight: 700;
}

.col-flex-end{
  align-items: flex-end;
}

.mr-20{
  margin-right: 50px;
}

.text-right{
  text-align: right;
}

.underlinetext-blue{
  text-decoration: underline;
  color: #35c4e4;
}

.font-18{
  font-size: 18px !important;
}

.text-blue{
  color: #35c4e4 !important;
}

.self-center{
  text-align: center !important;
}

.bullet-circle-img{
  width: 5px !important;
  height: 5px !important;
  object-fit: contain !important;
}

.ml-30{
  margin-left: 30px;
}

.font-footer{
  color: lightgray;
  font-weight: 400;
  font-size: 12px;
}


.inputnumber {
    border: 2px solid #aaa !important;
    border-radius: 4px;
    outline: none;
    padding: 8px;
    box-sizing: border-box;
    transition: 0.3s;
}

.th-width-org{
  min-width: 200px;
  max-width: 300px;
}

#outlined-adornment-password{
  cursor: pointer !important;
}

.reset-password-container{
  /* background: white;
    border-radius: 50%;
    position: relative;
    top: 20px;
    z-index: 1;
    width: 70px;
    height: 70px; */
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

.reset-password-icon {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

/* .position-absolute{
  position: absolute;
} */


.question-background {
  background-color: #273691 !important;
}

.question-logo {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.font-Adob-Garamond-Regular{
  font-family:adone-Garamond !important ;
}

.css-1ex1afd-MuiTableCell-root{
  font-family: unset !important;
}

.css-1cepzp5-MuiTableCell-root.MuiTableCell-head {
  font-family:adone-Garamond !important ;
}

.agency-heading{
  font-family:adone-Garamond ;
  font-weight: 700;
}

.flex-wrap {
  flex-wrap: wrap;
}

.new-blue-btn {
  border-radius: 1rem !important;
    width: 100px;
    color: #000b96;
    background: #000b96 !important;
    color: #fff;
}

.ml-smr {
  margin-left: 0.5rem !important;
}

.pd-none{
  padding: 0px !important;
}
.CircularProgressbar .CircularProgressbar-text{
  font-family: 'Monstserrat';
  font-weight: 900;
  font-size: 14px !important;
}
.pdf-text-color{
  color: #7f7f7f !important;
  font-size: 22px !important;
}
h6.sprint-heading{
  margin-bottom: 0px;
}
.sprint-lesting-container{
  margin: 0px;
    padding: 20px;
    border: 2px solid #fff !important;
    
}
.outofScope-listing span{
  
  color: #fff !important;
  font-size: 22px;
}
.outofScope-listing {
  background-color: none !important;
}
.graph-text{
  width: 60%;
  float: left;
  align-self: flex-end;
  text-align: center;
  font-family: 'Monstserrat';
  font-size: 14px;
  color: #7f7f7f;
  padding-left: 10px;
}
