

/****LeagueSpartan Font****/

@font-face {
    font-family: 'LeagueSpartan-Thin';
    src: url('../assets/fonts/newfonts/LeagueSpartan-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'LeagueSpartan-Regular';
    src: url('../assets/fonts/newfonts/LeagueSpartan-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'LeagueSpartan-Medium';
    src: url('../assets/fonts/newfonts/LeagueSpartan-Medium.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'LeagueSpartan-SemiBold';
    src: url('../assets/fonts/newfonts/LeagueSpartan-SemiBold.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'LeagueSpartan-Bold';
    src: url('../assets/fonts/newfonts/LeagueSpartan-Bold.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'LeagueSpartan-ExtraBold';
    src: url('../assets/fonts/newfonts/LeagueSpartan-ExtraBold.ttf');
    font-weight: normal;
    font-style: normal;
}

/****GT-America-Extended****/

@font-face {
    font-family: 'GT-America-Extended-Light-Trial';
    src: url('../assets/fonts/newfonts/GT-America-Extended-Light-Trial.otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GT-America-Extended-Regular-Trial';
    src: url('../assets/fonts/newfonts/GT-America-Extended-Regular-Trial.otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GT-America-Extended-Bold-Trial';
    src: url('../assets/fonts/newfonts/GT-America-Extended-Bold-Trial.otf');
    font-weight: normal;
    font-style: normal;
}

/****AGaramondPro****/

@font-face {
    font-family: 'AGaramondPro-Regular';
    src: url('../assets/fonts/newfonts/AGaramondPro-Regular.otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AGaramondPro-Bold';
    src: url('../assets/fonts/newfonts/AGaramondPro-Bold.otf');
    font-weight: normal;
    font-style: normal;
}

/****AustinCyr-Fat****/

@font-face {
    font-family: 'AustinCyr-Fat';
    src: url('../assets/fonts/newfonts//AustinCyr-Fat.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AustinCyr-Medium';
    src: url('../assets/fonts/newfonts//AustinCyr-MediumItalic.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AustinCyr-SemiboldItalic';
    src: url('../assets/fonts/newfonts/AustinCyr-SemiboldItalic.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AustinCyr-BoldItalic';
    src: url('../assets/fonts/newfonts/AustinCyr-BoldItalic.ttf');
    font-weight: normal;
    font-style: normal;
}


/****AdobeGaramond****/

@font-face {
    font-family: 'AdobeGaramond';
    src: url('../assets/fonts/newfonts/Adobe\ Garamond\ Regular.otf');
    font-weight: normal;
    font-style: normal;
}

