/*=====Reset CSS======*/
article,
aside,
canvas,
figure,
figcaption,
footer,
header,
nav,
section,
audio,
video,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol {
  margin: 0 ;
  padding: 0 ;
  border: 0  ;
}

a,
button,
input,
input[type="buttton"],
input[type="submit"],
select,
.btn,
textarea {
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  text-decoration: none !important;
  font-style: normal;
}

a:focus,
button:focus,
input:focus,
input[type="buttton"]:focus,
input[type="submit"]:focus,
select:focus,
.btn:focus,
textarea:focus,
.bootstrap-select .dropdown-toggle:focus {
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none !important;
}

img {
  max-width: 100%;
  height: auto;
}


a,
label,
span {
  display: inline-block;
  vertical-align: middle;
  vertical-align: 0;
}

figure {
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
  margin: 0;
  font-size: 0;
  line-height: 100%;
}

figure img {
  max-width: 100%;
}

ul,
ol {
  list-style: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

html {
  scroll-behavior: smooth;
}

/* Variables */
/* Custom Scrollbar */
.question_page ::-webkit-scrollbar {
  width: 5px !important;
  height: 10px !important;
}

 #question_page ::-webkit-scrollbar-thumb {
  background: #000b96 !important;
  border-radius: 40px;
}

::-webkit-scrollbar-track {
  background-color: #fff;
  -webkit-box-shadow: rgba(0, 0, 0, 0.22) -5px -2px 11px inset;
          box-shadow: rgba(0, 0, 0, 0.22) -5px -2px 11px inset;
}

/* Custom Selection */
::-moz-selection {
  background: #000b96 !important;
  color: #fff;
}

.question_page ::selection {
  background: #000b96;
  color: #fff;
}

body {
  min-height: 100%;
  font-family: 'WorkSans-Regular';
  font-size: 20px;
  font-weight: normal;
  line-height: 28px;
  color: #242021;
  background-color: #fff;
  position: relative;
  overflow-x: hidden;
}

.space_left {
  padding-left: 200px ;
}

.socialbar {
  padding: 0 14px 0 75px;
  height: 100vh;
  background: transparent;
  border-right: 1px solid #2e45af;
  position: fixed;
  z-index: 6;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  
}

.mobile-socialbar {
  height: 100vh;
  background: transparent;
  position: fixed;
  z-index: 6;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  right: 0;
}

.submit-request {
    width: 200px;
    height: 50px;
    background: transparent;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    top: 50%;
    right: 0;
    color: white !important;
    background-color: #092ea8;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    /* transform: rotate(270deg); */
    font-size: 14px;
    cursor: pointer;
    font-family: 'LeagueSpartan-Bold';
    letter-spacing: 1.5px;
    border-radius: 3rem;
  }
  
  .submit-request :hover {
    background-color: #fff;
    color: #000;
    border: 1px solid #092ea8;
    border-radius: 3rem;
  }

  .submit-request span:hover {
    border: none ;
  }




.socialbar ul {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.mobile-socialbar ul {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10px;
  background-color: #fff;
}

.socialbar ul li {
  margin: 50px 0px;
  text-align: left;
  padding: 0;
  font-size: 14px;
  text-transform: uppercase;
  
}

.mobile-socialbar ul li {
  margin: 10px 0px;
  text-align: left;
  padding: 0;
  font-size: 14px;
}

.socialbar ul li a {
  color: #fff;
  font-family: 'AustinCyr-SemiboldItalic'
}

.inner_scrollbar {
  border-right: 1px solid #ccc;
}

.inner_scrollbar ul li a {
  color: #7f7f7f;
  font-family: 'LeagueSpartan-Bold';
}

.mobile-socialbar ul li a {
  color: #000;
}

.socialbar ul li a span {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  display: block;
  margin: 0px 0px 0px -50px;
  padding: 0px;
  letter-spacing: 1.5px;
}

.socialbar ul li i.fab {
  padding-top: 2px;
}

/* mobile social bar */
.social-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: 1px;
  margin-bottom: 5px;
  position: relative;
  -webkit-transition: left 1s;
  transition: left 1s;
  color: #fff;
  border-bottom: 1px solid;
  border-right: 1px solid;
  border-top: 1px solid;
}

.social {
  position: fixed;
  top: 50%;
  z-index: 999999;
  height: 100vh;
  display: none;
}

.social a {
  text-decoration: none;
  display: block;
}

.color-telegram {
  background-color: #0084c6;
}

.color-instagram {
  background-color: #f62782;
}

.color-whatsapp {
  background-color: #24cc63;
}

.google-font {
  font-family: "Lato", sans-serif;
  font-size: 1.25rem;
}

.social-btn img {
  width: 40px;
}

.social-btn p {
  color: white;
  margin-top: 0px;
  margin-bottom: 0px;
}

.mobile-socialbar {
  display: none;
}

.headerbg {
  background: #092ea8;
  padding: 5px 0px 0px 0px;
  height: 100vh;
  position: relative;
}

.menu_trigger {
  display: none;
}

.menu_trigger .menu_btn span {
  height: 3px;
  width: 35px;
  background: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  display: block;
  border-radius: 40px;
}

.header_left .menu_trigger .menu_btn span {
  background: #000;
}

.custom_sticky_header .menu_trigger .menu_btn span {
  background: #000;
}

.menu_trigger .menu_btn span + span {
  margin-top: 9px;
  width: 25px;
  margin-left: 10px;
}

.mobile-menu-list .mobile-sub-menu {
  padding-left: 10px;
  border-left: 1px solid #000;
  margin-left: 14px;
}

.mobile-menu-list .nav-link {
  font-family: 'GT-America-Extended-Bold-Trial';
  margin: 0;
  line-height: 30px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: #000;
}

.mobile-menu-list .nav-link p {
  white-space: nowrap;
  position: relative;
  -webkit-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0);
  display: block;
  height: auto;
  opacity: 1;
}

.mobile-menu-list .caret {
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  margin-top: 13px;
  position: absolute;
  right: 0;
  -webkit-transition: all 150ms ease-in;
  transition: all 150ms ease-in;
}

.mobile-menu-list a[data-toggle=collapse][aria-expanded=true] .caret {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.navbar {
  padding: 0;
  margin:  0 0;
}

.custom_sticky_header {
  background-color: #fff;
  -webkit-box-shadow: 0 0 0.8125rem 0 rgba(77, 82, 94, 0.15);
          box-shadow: 0 0 0.8125rem 0 rgba(77, 82, 94, 0.15);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.custom_sticky_header .navbottomborder {
  position: relative;
}

.custom_sticky_header .navbottomborder::before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url('../assets//images/inner_sticky_header_bg.png') left top no-repeat;
  -webkit-animation: headerimg 5s linear infinite;
          animation: headerimg 5s linear infinite;
}

.custom_sticky_header .navbar {
  padding: 0;
  margin: 0;
}

.custom_sticky_header .navbar-brand {
  padding: 0;
}

.custom_sticky_header .navbar-expand-lg .navbar-nav .nav-link {
  padding: 35px 0;
}

.navbar-brand img {
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.custom_sticky_header .navbar-brand img {
  -webkit-filter: invert(1);
          filter: invert(1);
  -webkit-transform: scale(0.75);
          transform: scale(0.75);
}

.custom_sticky_header .navbottomborder {
  border-bottom: none;
}

.custom_sticky_header .navbar-brand::before {
  display: none;
}

.custom_sticky_header .navbar-brand::after {
  display: none;
}

.navbar-expand-lg .navbar-nav .nav-link::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  background: #fff;
  top: 99%;
  left: 0;
  pointer-events: none;
  -webkit-transform-origin: 100% 50%;
          transform-origin: 100% 50%;
  -webkit-transform: scale3d(0, 1, 1);
          transform: scale3d(0, 1, 1);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1), -webkit-transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
}


.navbar-expand-lg .navbar-nav .nav-link:hover::before {
  -webkit-transform-origin: 0% 50%;
          transform-origin: 0% 50%;
  -webkit-transform: scale3d(1, 1, 1);
          transform: scale3d(1, 1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.4, 1, 0.8, 1);
          transition-timing-function: cubic-bezier(0.4, 1, 0.8, 1);
}

.custom_sticky_header .navbar-expand-lg .navbar-nav .nav-link::before {
  background: #000;
  top: 99% !important;
}













.nav-item:hover .custom-dropdown-menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
}

.custom-dropdown-menu {
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0;
  width: 320px;
  visibility: hidden;
  background-color: #fff;
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  -webkit-box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.08);
          box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.08);
  padding: 20px;
  z-index: 9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.nav-item {
  position: relative;
}

.mega-menu::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.mega-menu::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 0px;
}

.nav-item:hover .mega-menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
}

.custom_sticky_header .mega-menu {
  background-color: #ffffff;
}

.custom_sticky_header .mega-menu h5,
.custom_sticky_header .sub_menu_list li a {
  color: #000;
}

/* .custom_sticky_header .mega-menu {
  top: 120px;
} */

.mega-menu {
  position: fixed;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  height: 100vh;
  /* top: 130px; */
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  -webkit-box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.08);
          box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.08);
  padding-bottom: 20px;
  z-index: 6;
  /* overflow-y: auto; */
  background: #fff;
  -webkit-animation: animatedBackground 30s linear infinite;
          animation: animatedBackground 30s linear infinite;
}

.mega-menu h5 {
  padding-bottom: 10px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  padding-top: 10px;
  margin-bottom: 10px;
  position: relative;
  text-transform: capitalize;
  font-family: 'GT-America-Extended-Light-Trial';
}

.mega-menu a{
  color: #212529 !important;
}
.mega-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 50px;

}

.mega-header .image-wrape , .contact-nav-container .image-wrape  {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  /* background-color: aliceblue; */
  background-color: rgba(0, 11, 150, 0.05);
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: 15px;
}

.mega-header .image-wrape img , .contact-nav-container .image-wrape img{
  width: 20px ;
}

.mega-header:hover {
  background-color: rgba(158, 158, 158, 0.1);
  
}

.icon_text-center:hover {
  background-color: rgba(158, 158, 158, 0.1);
  padding: 0.8rem;
  border-radius: 2rem;
}





.contact-social-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
}

.contact-social-list li a {
  color: #000;
  font-size: 16px;
  height: 35px;
  width: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 50%;
}

.contact-wrap h3 {
  padding-bottom: 10px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  padding-top: 10px;
  margin-bottom: 10px;
  position: relative;
  text-transform: uppercase;
  font-family: 'GT-America-Extended-Light-Trial';
}

/* .contact-social-list li a:hover {
  background-color: #000;
  color: #fff;
} */

.sub_menu_list li {
  font-size: 14px;
  font-family: GT-America-Extended-Light-Trial;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  padding: 5px 0;
}

.sub_menu_list li a {
  color: #000;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.sub_menu_list li a:hover svg {
  display: inline-block;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.sub_menu_list li a svg {
  display: none;
  margin-right: 10px;
}
.sub_menu_list li a:hover {
  margin-left: 10px;
}


.headerimg {
  background: url('../assets/images/header_bg2.png') left top no-repeat;
  height: 500px;
  width: 100%;
  position: absolute;
  -webkit-animation: headerimg 5s linear infinite;
          animation: headerimg 5s linear infinite;
  margin-top: -10px;
}

.headerbg::before {
  border-bottom: 1px solid #2e45af;
  position: absolute;
  content: '';
  width: 81.5%;
  height: 2px;
  margin-top: 6px;
  left: 50%;
  -webkit-transform: translateX(-39%);
          transform: translateX(-39%);
}

.header_left {
  padding-left: 160px ;
}

.header_left .custom-menu-trigger .menu_btn span {
  background: #fff;
}

.custom_sticky_header .custom-menu-trigger .menu_btn span {
  background: #000;
}

.navbottomborder {
  border-bottom: 1px solid #2e45af;
}

.navbar-brand::before {
  border-left: 1px solid #2e45af;
  position: absolute;
  content: '';
  width: 2px;
  height: 200px;
  margin-top: -30px;
}

.navbar-brand::after {
  border-right: 1px solid #2e45af;
  position: absolute;
  content: '';
  width: 2px;
  height: 200px;
  margin-top: -30px;
}

.custom_sticky_header .navbar-expand-lg .navbar-nav .nav-link {
  color: #000;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding: 50px 20px !important;
  color: #fff;
  font-size: 14px;
  font-family: 'AustinCyr-SemiboldItalic';
  text-transform: uppercase;
  letter-spacing: 1.5px;
  position: relative;
  margin-right: 10px;
}

.navbutton li a {
  margin: 0px 12px;
  background-color: #000;
  color: #fff;
  font-size: 14px;
  font-family: 'LeagueSpartan-Bold';
  padding: 10px 29px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  border-radius: 30px;
  -webkit-box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
}

.navbutton li a:hover {
  background-color: #fff;
  color: #000;
}



.navbutton li:last-child a {
  background-color: #fff;
  color: #000;
}

.navbutton li:last-child a:hover {
  background-color: #000;
  color: #fff;
}

.bnr-sec {
  position: relative;
}

.bnr-sec-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  top: 0;
  bottom: 0;
  /* height: calc(100vh - 100px); */
  height: calc(100vh - 0px);
  padding-left: 50px;
}

.bnr-sec h1 {
  color: #fff;
  font-family: 'GT-America-Extended-Light-Trial';
  font-size: 40px;
  line-height: 50px;
  /* max-width: 380px; */
}

.bnr-sec p {
  font-size: 26px;
  line-height: 30px;
  font-family: 'AustinCyr-SemiboldItalic';
  font-style: italic;
  color: #ffffff;
  padding-top: 30px;
  max-width: 355px;
}

.bnr-sec h1 span {
  font-weight: 600;
  font-family: 'GT-America-Extended-Bold-Trial';
}

/* global classes */
.tittle {
  font-family: 'GT-America-Extended-Light-Trial';
  font-size: 60px;
  line-height: 64px;
}

.tittle span {
  display: block;
  font-weight: 800;
  color: #000b96;
}

/* sec-two css start */
.sec-two-info {
  padding: 150px 0;
}

.sec-two-info h2 {
  font-family: 'GT-America-Extended-Light-Trial';
  font-size: 60px;
  line-height: 64px;
  text-align: inherit
}

.sec-two-info h2 span {
  display: block;
  font-weight: 800;
  color: #000b96;
}

.sec-two-info p {
  font-family: 'AGaramondPro-Regular';
  font-size: 18px;
  line-height: 22px;
  padding: 30px 0 40px;
}

.sec-two-info .btn-container a {
  font-family: 'LeagueSpartan-Bold';
  font-size: 14px;
  line-height: 18px;
  color: #4e4e4e;
  font-weight: 600;
  border: 2px solid #565372;
  padding: 18px 30px 16px;
  border-radius: 40px;
  margin-right: 1rem;
  color: #000;
  
}

.btn-seclted {
    background-color: #000b96;
    color: #fff !important;
}

.sec-two-info .btn-container a:hover {
  background-color: #000b96;
  color: #fff;
  border: 2px solid #000b96;
}




.sec-two-info .btn-container a:hover {
  background-color: #000b96;
  color: #fff;
  border: 2px solid #000b96;
}


.sec-two-info .btn-container-first  a:hover {
    background-color: #fff !important;
    color: #000 !important;
}
.sec-two-info .btn-container {
  padding-bottom: 62px;
}

.main-sec-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 38px 0 38px 35px;
}

.main-sec-wrap figcaption::after,
.list-img-container figure::before,
.list-img-container::after {
  display: none;
}

.img-container {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 16%;
          flex: 0 0 16%;
  position: relative;
}

.img-container::before {
  height: 38px;
  width: 1px;
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  margin: auto;
  background-color: #ddd;
  top: -37px;
}

.main-sec-wrap {
  opacity: 0;
}

.custom_animation .main-sec-wrap {
  -webkit-animation-name: fade_slow;
          animation-name: fade_slow;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}

.custom_animation .second-main-sec-wrap {
  -webkit-animation-delay: 1.4s;
          animation-delay: 1.4s;
}

.custom_animation .third-main-sec-wrap {
  -webkit-animation-delay: 2.1s;
          animation-delay: 2.1s;
}

.custom_animation .img-container::before {
  background-color: #171599;
  -webkit-animation: border_anim_height linear 0.7s forwards;
          animation: border_anim_height linear 0.7s forwards;
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}

.custom_animation .second-img-container::before {
  -webkit-animation-delay: 1.4s;
          animation-delay: 1.4s;
}

.custom_animation .third-img-container::before {
  -webkit-animation-delay: 2.1s;
          animation-delay: 2.1s;
}

.main-sec-wrap-list li .main-sec-wrap {
  position: relative;
}

.main-sec-wrap-list li .main-sec-wrap::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  top: 0;
  left: 0;
  background-color: #ddd;
}

.main-sec-wrap-list li:last-child .main-sec-wrap {
  padding: 38px 0 0px 35px;
}

.main-sec-wrap figure {
  width: 142px;
  height: 142px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 50%;
  /* box-shadow: rgba(149, 157, 165, 0.53) -7px -9px 30px inset; */
  -webkit-box-shadow: rgba(0, 0, 0, 0.22) -5px -2px 11px inset;
          box-shadow: rgba(0, 0, 0, 0.22) -5px -2px 11px inset;
  overflow: hidden;
  position: relative;
}

.main-sec-wrap figcaption {
  margin-left: 38px;
  text-align: justify;
}

.main-sec-wrap figcaption h3 {
  font-size: 26px;
  line-height: 30px;
  font-family: 'AdobeGaramondRegular';
  font-style: italic;
  font-weight: 800;
  color: #070707;
}

.main-sec-wrap figcaption p {
  color: #4e4e4e;
  font-family: 'AGaramondPro-Regular';
  font-size: 18px;
  line-height: 22px;
  padding: 30px 0 0;
}

.main-sec-left-wrap {
  position: fixed;
  left: 70%;
  -webkit-transform: translateX(-30%);
  transform: translateX(-30%);
  background: url('../assets/images/rainmakers_logo.png') left top no-repeat ;
  background-size: 100%;
  width: 345px;
  height: 540px;
  z-index: -1;
  top: 0%;
  margin-top: 135px;
}
.scroll_txt {
  position: relative;
}

.main-sec-left-wrap .drop-img {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -380px;
  width: 114px;
  height: 178px;
  background: url('../assets/images/main-wrap-drop-img.png') top center no-repeat;
  width: 99px;
  height: 153px;
  background-size: 100%;
  z-index: 1;
}

.left-wrap-main-img {
  width: 399px;
  height: 570px;
  overflow: hidden;
  position: relative;
}

.left-wrap-main-img img {
  max-width: 100%;
}

.top-sec {
  padding-left: 35px;
}

.custom_animation .line_animation::after {
  position: absolute;
  content: "";
  width: 1060px;
  height: 1px;
  top: 0;
  left: 0;
  background-color: #171599;
  -webkit-animation: border_anim_width 0.7s linear forwards;
          animation: border_anim_width 0.7s linear forwards;
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}

.custom_animation .second-main-sec-wrap.line_animation::after {
  -webkit-animation-delay: 1.4s;
          animation-delay: 1.4s;
}

.custom_animation .third-main-sec-wrap.line_animation::after {
  -webkit-animation-delay: 2.1s;
          animation-delay: 2.1s;
}

.custom_animation .drop-animate figure::before {
  content: "";
  position: absolute;
  bottom: -50%;
  left: -50%;
  height: 200%;
  width: 200%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: #171599;
  border-radius: 40%;
  /* opacity: 0.5; */
  -webkit-animation: spin 0.7s linear forwards;
          animation: spin 0.7s linear forwards;
  -webkit-box-shadow: rgba(0, 0, 0, 0.22) -5px -2px 11px inset;
          box-shadow: rgba(0, 0, 0, 0.22) -5px -2px 11px inset;
  z-index: -1;
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}

.sec-three {
  background-color: #000b96;
  position: relative;
  z-index: 5;
}

.sec-four {
  position: relative;
  z-index: 4;
  background-color: #fff;
}



.custom_animation .second-img-container.drop-animate figure::before {
  -webkit-animation-delay: 1.4s;
          animation-delay: 1.4s;
}

.custom_animation .third-img-container.drop-animate figure::before {
  -webkit-animation-delay: 2.1s;
          animation-delay: 2.1s;
}

.drop-animate figure img {
  -webkit-transition: all 0.2 ease-in-out;
  transition: all 0.2 ease-in-out;
  -webkit-animation: iconcolrchange 0.15s linear forwards;
          animation: iconcolrchange 0.15s linear forwards;
}

.custom_brdr {
  position: relative;
}

/* sec-two css end */
.no-space {
  padding-right: 0;
}

.service-right-wrap img {
  margin-left: -200px;
}

.what-list-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.what-list {
  padding: 30px 0 0;
}

.what-list li {
  font-family: 'AustinCyr-Medium';
  margin-bottom: 10px;
  position: relative;
  /* margin-left: 16px; */
  font-size: 20px;
  line-height: 24px;
}

/* .what-list li::before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ccc;
  top: 5px;
  left: -16px;
} */

.what-list li:last-child {
  margin-bottom: 0;
}

.what-list li a {
  color: #091282;
}

.main-sec-wrap.service-main-sec-wrap figcaption {
  text-align: left;
}

.footer-form {
  background-color: #000b96;
  padding: 160px 0 0;
  position: relative;
  z-index: 7;
}

.wave-img {
  text-align: center;
  padding-top: 50px;
  position: relative;
}

.wave-img img {

  -webkit-filter: brightness(0.1);
          filter: brightness(0.1);
  opacity: 0.3;
}


.form-content {
  padding-left: 50px;
}

.form-content h2 {
  font-family: 'GT-America-Extended-Light-Trial';
  font-size: 60px;
  line-height: 64px;
  color: #fff;
  padding-bottom: 0px;
}

.form-content h2 span {
  display: block;
  font-weight: 800;
}

.form-content p {
  color: #fff;
  font-family: 'AustinCyr-SemiboldItalic';
  font-size: 36px;
  line-height: 40px;
}

.form-content input {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #1a24a1;
  background-color: transparent;
  width: 100%;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  padding-bottom: 10px;
  padding-left: 20px;
  color: #fff;
}

::-webkit-input-placeholder {
  color: #fff;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  color: #fff;
  opacity: 1;
  /* Firefox */
}

::-ms-input-placeholder {
  color: #fff;
  opacity: 1;
  /* Firefox */
}

::placeholder {
  color: #fff;
  opacity: 1;
  /* Firefox */
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff;
}

.bottom-form {
  padding-top: 55px;
}

.form-content .submit_btn {
  font-family: 'LeagueSpartan-Bold';
  border: 2px solid #000;
  background-color: #000;
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  padding: 18px 50px 16px;
  border-radius: 40px;
  letter-spacing: 3.5px;
  margin-top: 50px;
  display: block;
  width: auto;
}

.form-content .submit_btn:hover {
  border: 2px solid #fff;
  background-color: #fff;
  color: #000;
}


.form-content button {
  font-family: 'LeagueSpartan-Bold';
  border: 2px solid #000;
  background-color: #000;
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  padding: 18px 50px 16px;
  border-radius: 40px;
  letter-spacing: 3.5px;
  margin-top: 50px;
}

.form-content button:hover {
  border: 2px solid #fff;
  background-color: #fff;
  color: #000;
}

.footer {
  background-color: #000b96;
  padding-bottom: 100px;
  position: relative;
  z-index: 6;
}

.footer .footer-content h6 {
  font-family: 'GT-America-Extended-Bold-Trial';
  color: #fff;
  font-size: 13px;
  line-height: 17px;
  text-transform: uppercase;
  letter-spacing: 3.5px;
  padding-bottom: 55px;
}

.footer .footer-content .footer-list li {
  font-size: 16px;
  margin-bottom: 34px;
  font-family: 'AdobeGaramond';
}

.footer .footer-content .footer-list li a {
  color: #afb5ff;
}

.footer .footer-content .footer-list li a:hover {
  color: #fff;
}

.footer .footer-content .footer-list li:last-child {
  margin-bottom: 0;
}

.social-list li a .svg-inline--fa {
  font-size: 20px;
}

.social-list li a span {
  color: #fff;
  margin-left: 24px;
}

.social-list li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.footer-info {
  padding-left: 50px;
  padding-top: 0px;
}

.footer-info .row {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.footer-logo span {
  font-family: 'AGaramondPro-Regular';
  color: #afb5ff;
  font-size: 15px;
  line-height: 19px;
  margin-top: 18px;
}

.water_drop {
  width: 142px;
  height: 142px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 64% 0 64% 64%;
  /* box-shadow: rgb(149 157 165 / 53%) -7px -9px 30px inset; */
  -webkit-box-shadow: rgba(0, 0, 0, 0.22) -5px -2px 11px inset;
          box-shadow: rgba(0, 0, 0, 0.22) -5px -2px 11px inset;
  overflow: hidden;
  position: relative;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.water_ripple_wrap {
  position: relative;
}

.wrapper {
  position: absolute;
  width: 100%;
  height: 300px;
  top: 0;
  bottom: 0;
}

.outer-shadow {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-box-shadow: -0.7rem 0.2rem 1rem rgba(34, 100, 158, 0.1), 0.7rem 0.2rem 1rem rgba(34, 100, 158, 0.1), 0 0.5rem 1rem rgba(34, 100, 158, 0.1), inset 0 -0.1rem 1rem rgba(34, 100, 158, 0.2), inset 0 0.1rem 1rem rgba(255, 255, 255, 0.1), 0 -0.3rem 1rem rgba(255, 255, 255, 0.1);
          box-shadow: -0.7rem 0.2rem 1rem rgba(34, 100, 158, 0.1), 0.7rem 0.2rem 1rem rgba(34, 100, 158, 0.1), 0 0.5rem 1rem rgba(34, 100, 158, 0.1), inset 0 -0.1rem 1rem rgba(34, 100, 158, 0.2), inset 0 0.1rem 1rem rgba(255, 255, 255, 0.1), 0 -0.3rem 1rem rgba(255, 255, 255, 0.1);
  -webkit-transform: scale(0);
          transform: scale(0);
}

.outer-shadow.submitdrop {
  -webkit-animation: wave 3s ease-out 1;
          animation: wave 3s ease-out 1;
}

.inner-shadow {
  height: 90%;
  width: 96%;
  border-radius: 50%;
  -webkit-box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.3), inset 0 -0.1rem 1rem rgba(0, 0, 0, 0.3), inset 0 0.5rem 1rem rgba(34, 100, 158, 0.2), inset 0.2rem 0 1rem rgba(34, 100, 158, 0.1), inset -0.2rem 0 1rem rgba(34, 100, 158, 0.1), 0 -0.1rem 1rem rgba(34, 100, 158, 0.2);
          box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.3), inset 0 -0.1rem 1rem rgba(0, 0, 0, 0.3), inset 0 0.5rem 1rem rgba(34, 100, 158, 0.2), inset 0.2rem 0 1rem rgba(34, 100, 158, 0.1), inset -0.2rem 0 1rem rgba(34, 100, 158, 0.1), 0 -0.1rem 1rem rgba(34, 100, 158, 0.2);
}

.submitdrop.wave_1 {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

.submitdrop.wave_2 {
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}

.submitdrop.wave_3 {
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
}

.align-center {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.drop {
  width: 100px;
  height: 100px;
  -webkit-transform: translateY(-590px);
          transform: translateY(-590px);
  opacity: 1;
}

.submitdrop {
  -webkit-animation: fall 1s linear 1 forwards;
          animation: fall 1s linear 1 forwards;
}

/*new css */
.footer-logo a img {
  max-width: 194px;
}

.footer-logo span {
  display: block;
}

.wave_wrap {
  text-align: center;
  margin-top: 15px;
}

.appear_img {
  -webkit-animation: slowappear 2s ease-out 1 forwards;
          animation: slowappear 2s ease-out 1 forwards;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
}

.bottom-copy {
  margin-top: 1.5rem !important;
  font-family: 'AGaramondPro-Regular';
  color: #afb5ff;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
}
.bottom-copy p {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.buttom-footer-logo img {
  max-width: 37px;
}

/* banener mving animation */
@-webkit-keyframes headerimg {
  0% {
    left: -5%;
  }
  100% {
    left: -20%;
  }
}
@keyframes headerimg {
  0% {
    left: -5%;
  }
  100% {
    left: -20%;
  }
}

/* water fill animations */
@-webkit-keyframes iconcolrchange {
  0% {
    -webkit-filter: none;
            filter: none;
  }
  30% {
    -webkit-filter: brightness(80);
            filter: brightness(80);
  }
  100% {
    -webkit-filter: brightness(200);
            filter: brightness(200);
  }
}
@keyframes iconcolrchange {
  0% {
    -webkit-filter: none;
            filter: none;
  }
  30% {
    -webkit-filter: brightness(80);
            filter: brightness(80);
  }
  100% {
    -webkit-filter: brightness(200);
            filter: brightness(200);
  }
}

@-webkit-keyframes border_anim_width {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@keyframes border_anim_width {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@-webkit-keyframes border_anim_height {
  0% {
    height: 0px;
  }
  100% {
    height: 38px;
  }
}

@keyframes border_anim_height {
  0% {
    height: 0px;
  }
  100% {
    height: 38px;
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: translateY(0%) rotate(360deg);
            transform: translateY(0%) rotate(360deg);
  }
  0% {
    -webkit-transform: translateY(100%) rotate(0deg);
            transform: translateY(100%) rotate(0deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: translateY(0%) rotate(360deg);
            transform: translateY(0%) rotate(360deg);
  }
  0% {
    -webkit-transform: translateY(100%) rotate(0deg);
            transform: translateY(100%) rotate(0deg);
  }
}

@-webkit-keyframes r-water {
  100% {
    -webkit-transform: translateY(0%) rotate(360deg);
            transform: translateY(0%) rotate(360deg);
    background-color: #171599;
  }
  0% {
    -webkit-transform: translateY(100%) rotate(0deg);
            transform: translateY(100%) rotate(0deg);
    background-color: #171599;
  }
}

@keyframes r-water {
  100% {
    -webkit-transform: translateY(0%) rotate(360deg);
            transform: translateY(0%) rotate(360deg);
    background-color: #171599;
  }
  0% {
    -webkit-transform: translateY(100%) rotate(0deg);
            transform: translateY(100%) rotate(0deg);
    background-color: #171599;
  }
}

/* water wave animations */
@-webkit-keyframes wave {
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
  }
}
@keyframes wave {
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
  }
}

@-webkit-keyframes fall {
  0% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(0rem);
            transform: translateY(0rem);
    opacity: 0;
  }
}

@keyframes fall {
  0% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(0rem);
            transform: translateY(0rem);
    opacity: 0;
  }
}

@-webkit-keyframes slowappear {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slowappear {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fade_slow {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade_slow {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.show-options {
    visibility: visible !important;
}

.boder-bottom-blue {
    border-bottom: 3px solid #000b96 !important;
}

.h1-width {
    width: 40%;
}

.error-text {
    color: red !important;
    font-size: 16px !important;
}

/* .wow {
  visibility: hidden;
} */

.scroll-line-color-white{
  border-color: rgb(255, 255, 255) !important;
}

.scroll-line-color-black{
  border-color: rgb(0, 0, 0) !important;
}

.z_index_1 {
  z-index: 1 !important; 
}

.z_index_2 {
  z-index: 5 !important; 
}

.no_padding {
  padding: 0 !important;
}

.ml-xxsm {
  margin-left: 0.3rem;
}

.ml-15{
  margin-left: 15px !important;
}

.custom-dropdown-menu ul, ol {
  padding-left: 0 !important;
}


.scroll-line-color-white{
  border-color: rgb(255, 255, 255) !important;
}

.scroll-line-color-black{
  border-color: rgb(0, 0, 0) !important;
}



.socialbar-color-white a{
  color: rgb(255, 255, 255) !important;
}

.socialbar-color-black a {
 color: rgb(0, 0, 0) !important;
}

.padding-top-xxxl {
  padding-top: 160px !important;
}

.left-content h2 {
  text-transform: none !important;
}

.pb-25{
  padding-bottom: 25px !important;
}

.socialbar  ul {
  margin: 0 !important;
  padding: 0 !important;
  border: 0 !important;
}
/* .wow {
  visibility: hidden;
} */

.pr-15{
  padding-right: 15px !important;
}

.pl-0{
  padding-left: 0 !important;
}

.question_page .font-black {
  color: #212529;
}


.top-sec h2 {
  text-transform: none !important;
}

.question_page .pd-1 {
  padding-top: 1rem;
}

.overflow-y span{
  display: initial !important;
}

#sidebar-wrapper a {
 border-bottom: 1px solid #dee2e6  !important;
}

#sidebar-wrapper a:last-child {
  border-bottom: 1px solid #dee2e6  !important;
 }

 .question_page .submit-btn-center {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
 }

 
#adminDashboard input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    margin: 0 !important; 
}
 
.question_page .text-bold{
  font-weight: 700;
}

.question_page .check-container {
  display: flex;
}

.mega-container-fluid{
  width: 100%;
    padding-right: 15px !important;
    padding-left: 15px ;
    margin-right: auto !important;
    margin-left: auto !important;
}

.mega-menu .sub_menu_list ol,ul{
  padding-left: 0 !important;
}

.question_page .display-none {
  display: none ;
}

.mega-menu .space_left {
  padding-left: 40px !important;
}

.mega-menu .sub_menu_list {
  margin-left: 70px;
}

#question_page .mega-menu::-webkit-scrollbar-thumb {
  background: #000 !important;
  border-radius: 0px;
}

.mobile-footer-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-footer-logo a img {
    max-width: 70px;
}

.picture-div {
  position: fixed;
  left: 80%;
  -webkit-transform: translateX(-30%);
  transform: translateX(-30%);
  background-size: 100%;
  width: 300px;
  height: 540px;
  z-index: 0;
  top: 5%;
  margin-top: 135px;
}

.question_page .mt-xl{
  margin-top: 2rem;
}

.form-content-two {
  margin-top: 2rem !important;
}

.form-content-two input {
   color: #242021 !important;
}

.form-content-two input::-webkit-input-placeholder {
  color: #242021 !important;
}

.question_page .display-none{
  display: none !important;
}

.sticky-top{
  position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
}

.question_page .hight-50 {
  height: 50vh;
}

.question_page .contact-nav-container {
  width: 30%;
  height: 45vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-transform: capitalize;
    padding: 10px;
    font-family: 'GT-America-Extended-Light-Trial';
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    border-radius: 50px;
}

.question_page .overFlow_hidden {
  overflow: hidden;
}

.question_page .icon_text-center {
  display: flex;
  align-items: center;
}

.question_page .mr-1 {
  margin-right: 2rem !important; 
}

 #mobile_nav .nav-link {
   padding: 5px 0 !important;
}

#mobile_nav .sub_menu_list a {
  color: #fff;
  
}

#mobile_nav .sub_menu_list li {
  text-align: right;
  
}

#mobile_nav .sub_menu_list  {
 width: 100%;
  
}

#mobile_nav .megamenu-li .sub_menu_list li  {
  text-align: left;
  padding-left: 6rem;
}
#mobile_nav .custom-drop-wrap .sub_menu_list li  {
  text-align: left;
  padding-left: 10rem;
}

#mobile_nav .megamenu-li h5  {
 color: #fff;
 padding-left: 4rem;
}

#mobile_nav .dropdown-toggle::after {
  margin-left: -5rem;
}

#mobile_nav .mega-header   {
  width: 100%;
  align-self: center;
 }

#mobile_nav .col-md-6  {
  display: flex;
    flex-direction: column;
    align-items: end;
 }


#mobile_nav ::-webkit-scrollbar {
  width: 5px !important;
}

.custom-drop-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.question_page .display-mob {
  margin: 0px 5px;
  padding-bottom: 2rem;
}

.question_page .display-mob li a  {
  
  font-size: 10px;
  font-family: 'LeagueSpartan-Bold';
  
}

.question_page .mega-container-overflow {
  overflow-y: scroll;
  height: 80vh;
}

#services-chose p {
  padding: 0;
  font-size: 16px;
  font-weight:500;
  margin-bottom: 0rem;
}

.btn-lf-margin {
  margin-left: 1rem;
}

.question_page .submit-button {
  font-family: 'LeagueSpartan-Bold';
  border: 2px solid #000;
  background-color: #000b96;
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  padding: 18px 50px 16px;
  border-radius: 40px;
  letter-spacing: 3.5px;
  margin-top: 1rem;
  display: block;
  width: auto;
  border-color: #000b96;
}

.question_page .submit-button:hover:not([disabled]) {
  background-color: #fff;
  color:black !important;
  border: 2px solid #000b96;
}


.question_page .submit-button-disabled {
    background-color: #c7c3c3;
    outline: none;
    border: none;
    color: #7c7878 !important;
}

.question_page .sbt-sec-btn-disabled {
  color: #7c7878 !important;
  background-color: #c7c3c3 !important;
  border: none !important;
}

.question_page .sbt-sec-btn {
  font-family: 'LeagueSpartan-Bold';
  border: 2px solid #000;
  background-color: #000;
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  padding: 18px 50px 16px;
  border-radius: 40px;
  letter-spacing: 3.5px;
  margin-top: 50px;
  display: block;
  width: auto;
}


.bottom-form input[disabled]:hover {
  border: 2px solid #fff !important;
  background-color: #fff !important;
  color: #000 !important;
}